import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { cloneDeep } from 'lodash';

import { ProjectService } from 'src/app/modules/project/services/project.service';

import { PluginService } from 'src/app/modules/plugins/plugin.service';
import { Project } from 'src/app/model/project.model';
import { SWPlugin } from 'src/app/model/swplugin.model';
import { ProjectData } from 'src/app/model/project-data.model';
import { Subscription } from 'rxjs';
import { UIService } from 'src/app/core/services/ui.service';
import { delay } from 'rxjs/operators';
import { SketchupService } from 'src/app/services/sketchup.service';
import { SwConfirmDialogComponent } from 'src/app/shared/sw-confirm-dialog';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'sw-project-plugins',
  templateUrl: './project-plugins.component.html',
  styleUrls: ['./project-plugins.component.scss'],
})
export class ProjectPluginsComponent implements OnInit, OnDestroy {
  project: Project;
  oldProject: Project;
  errorMessage: string;

  addingPluginID: string = null;

  plugins: SWPlugin[];

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private pluginService: PluginService,
    private location: Location,
    private projectService: ProjectService,
    private uiService: UIService,
    public sketchupService: SketchupService,
    public dialog: MatDialog,
    private translate: TranslateService
  ) {}

  routeSubscriber: Subscription;

  ngOnInit(): void {
    this.plugins = this.pluginService.plugins;
    console.log(this.plugins)
    // observer for `id` param in route
    this.reloadProject();
  }

  reloadProject() {
    this.routeSubscriber?.unsubscribe();
    this.routeSubscriber = this.activeRoute.params
      .pipe(delay(0))
      .subscribe((params) => this.updateRoute(params));
  }

  async updateRoute(params) {
    this.uiService.disableUI(false);
    this.errorMessage = undefined;
    try {
      const currentProject = await this.projectService.fetchProject(params.id);
      this.oldProject = currentProject;
      this.project = cloneDeep(currentProject);

      // this.sketchupService.checkProjectFileLinking(this.project)
    } catch (e) {
      this.errorMessage = this.translate.instant('PROJECT.CANT_LOAD');
      console.error(e);
      this.uiService.showErrors(e);
    }
    this.uiService.enableUI();
  }

  ngOnDestroy() {
    this.routeSubscriber.unsubscribe();
  }

  // is the plugin added to the project?
  isActive(pluginID: string): boolean {
    return this.project.data.some((d) => d.pluginID === pluginID);
  }

  // get template name that matches the pluginID
  // (used to display 'based on TemplateName')
  templateName(pluginID: string): string {
    const pluginData = this.project.data.find((d) => d.pluginID === pluginID);

    if (Boolean(pluginData.templateType)) {
      return this.translate.instant('PLUGINS.ADD_TEMPLATE', pluginData);
    } else {
      return this.translate.instant('PLUGINS.BASED_ON_TEMPLATE', pluginData);
    }
  }

  tryPlugin(pluginID: string) {
    alert('open external plugin page link in new window');
  }

  removePluginData(plugin: SWPlugin) {
    const pluginData = this.project.data.find((d) => d.pluginID === plugin.id);

    if (Boolean(pluginData.templateType)) {
      // remove it with no confirmation, as it wasn't yet added to the project
      this.project.data = this.project.data.filter(
        (d) => d.pluginID !== plugin.id
      );
    } else {
      this.removePluginDialog(plugin);
    }

    // We do not call the delete API endpoint here.
    // deletion/insertion of templates will be made after clicking DONE
  }

  removePluginDialog(plugin: SWPlugin) {
    const dialogRef = this.dialog.open(SwConfirmDialogComponent, {
      width: '300px',
      data: {
        title: this.translate.instant('REMOVE_PLUGIN.TITLE'),
        prompt: this.translate.instant('REMOVE_PLUGIN.MESSAGE', plugin),
        cancelButton: this.translate.instant('GLOBAL.CANCEL'),
        actionButton: this.translate.instant('GLOBAL.OK'),
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result)) {
        this.project.data = this.project.data.filter(
          (d) => d.pluginID !== plugin.id
        );
      } else {
        // Canceled
      }
    });
  }

  async fetchTemplates(plugin: SWPlugin) {
    this.addingPluginID = plugin.id;
    plugin.templates = await this.pluginService.fetchTemplates(plugin.id);

    if (plugin.library || plugin.id == 'fabric8') {
      this.selectTemplate(plugin.templates[0]);
    } else {
      this.uiService.disableUI(false);
    }
  }

  cancelAddingPlugin() {
    this.addingPluginID = null;
    this.uiService.enableUI();
  }

  selectTemplate(template: ProjectData) {
    const t = JSON.parse(JSON.stringify(template));
    this.project.data.push(t);
    this.addingPluginID = null; //hide list
    this.uiService.enableUI();

    // We do not/should not call the delete API endpoint here.
    // deletion/insertion of templates will be made after clicking DONE
  }

  deleteTemplate(template: ProjectData, plugin: SWPlugin) {
    const dialogRef = this.dialog.open(SwConfirmDialogComponent, {
      width: '300px',
      data: {
        title: this.translate.instant('DELETE_TEMPLATE.TITLE'),
        prompt: this.translate.instant('DELETE_TEMPLATE.MESSAGE', template),
        cancelButton: this.translate.instant('GLOBAL.CANCEL'),
        actionButton: this.translate.instant('GLOBAL.OK'),
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (Boolean(result)) {
        await this.pluginService.deleteTemplate(template.id);
        plugin.templates.forEach((t) => console.log(t));
        plugin.templates = plugin.templates.filter((t) => t.id !== template.id);
      } else {
        // Canceled
      }
    });
  }

  async onDone() {
    this.uiService.disableUI(true);
    try {
      await this.projectService.updateTemplates(this.oldProject, this.project);
      const updatedProject = await this.projectService.updateProjectMetadata(
        this.project
      );
      this.projectService.setProjectReference(updatedProject, true);

      this.projectService.edit(this.project.id);
    } catch (err) {
      throw err;
    }
    this.uiService.enableUI();
  }

  onCancel() {
    this.location.back();
    this.uiService.enableUI();
  }

  openSettings() {
    this.projectService.openSettings(this.project.id);
  }

  openModelManager() {
    if (this.sketchupService.isSketchup == true) {
      this.sketchupService.bridge.call('openModelManager');
    } else {
      alert(this.translate.instant('PLUGINS.MM_SKETCHUP_ONLY'));
    }
  }

  openFabric8() {
    // const n = [{
    //   outlets: {
    //     detail: ['/fabric8']
    //   }
    // }]
    this.router.navigate(['/fabric8']);
  }
}
