import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { SWPlugin } from '../../model/swplugin.model';
import { ProjectData } from '../../model/project-data.model';
import { AuthService, UserObject } from '../../core/services/auth.service';
import { SketchupService } from 'src/app/services/sketchup.service';

@Injectable({
  providedIn: 'root',
})
export class PluginService implements OnDestroy {
  userSubscription: Subscription;

  constructor(private http: HttpClient, private authService: AuthService) {
    this.userSubscription = this.authService.user$.subscribe((u) => {
      console.log(u)
      if (Boolean(u) == true) {
        this.setPluginAvailabilityForUser(u);
      }
    });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe();
  }

  plugins: SWPlugin[] = [
    new SWPlugin('sketchup-objects', 'SketchUp Objects', true, [], false),
    new SWPlugin('pap-basic', 'PlanAProject Basic', true, [], false),
    new SWPlugin('MM_14d_', 'Model Manager', false, [], false),
    new SWPlugin('pmo-basic', 'PlanMateOut Basic', false, [], false),
    new SWPlugin('cabn8', 'CabN8', false, [], false),
    new SWPlugin('cabn8-calc', 'Calculate CabN8', false, [], false),
    new SWPlugin('gardn8', 'GardN8 Library', false, [], true),
    new SWPlugin('gardn8-calc', 'Calculate GardN8', false, [], false),
    new SWPlugin('smart-build', 'SmartBuild', false, [], false),
    // new SWPlugin('fabric8', 'Fabric8', true, [], false)
  ];

  // forceEnable = ['sketchup-objects', 'fabric8', 'model-manager'];
  forceEnable = window['__forceEnable'] || [];

  public setPluginAvailabilityForUser(user: UserObject) {
    //TODO: call authService.refreshToken before the call to this function
    //TODO: extract the user object/licenses from the token
    this.plugins.forEach((plugin, index) => {
      this.plugins[index].available = false;
      const found = user.licenses.find((licenseID) => licenseID == plugin.id);
      if (Boolean(found)) {
        plugin.available = true;
      }

      if (this.forceEnable.includes(plugin.id)) {
        plugin.available = true;
      }
    });
  }

  //TODO:
  public forceCheckPluginAccess(pluginID: string) {
    // refresh the token, to get the latest claims
    this.authService.getOrRefreshToken();

    // check if the user has the required claims
    //TODO
  }

  public fetchTemplates(pluginID: string): Promise<ProjectData[]> {
    console.log('fetching plugin templates');
    return this.http
      .get<ProjectData[]>(environment.apiUrl + '/templates/' + pluginID)
      .toPromise();
  }

  public deleteTemplate(templateID: string) {
    return this.http
      .delete(environment.apiUrl + '/templates/' + templateID)
      .toPromise();
  }

  public pluginWithID(pluginID: string): SWPlugin {
    return this.plugins.find((p) => p.id == pluginID);
  }
}
